import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import UIKitMeeting from "../components/UIKitMeeting";
import Watermark, {
  WatermarkConfig,
  WatermarkPosition,
} from "../components/Watermark";
import FlagsmithController from "../controllers/FlagsmithController";
import { MeetingConfig } from "../types";

const DEFAULT_WATERMARK_CONFIG: WatermarkConfig = {
  url: "https://dyte.io/images/favicon.png",
  position: WatermarkPosition.TopLeft,
  size: {
    width: 200,
  },
  opacity: 1,
  enabled: false,
};

const DEFAULT_UIKIT = false;
const DEFAULT_WAIT_TIME_MS = 60000;

const DEFAULT_MEETING_CONFIG: MeetingConfig = {
  uiKit: DEFAULT_UIKIT,
  waitTimeMs: DEFAULT_WAIT_TIME_MS,
  watermark: DEFAULT_WATERMARK_CONFIG,
};

function Meeting() {
  const [searchParams] = useSearchParams();
  const [config, setConfig] = useState<MeetingConfig | null>(null);
  const [flagsmith, setFlagsmith] = useState<FlagsmithController | null>(null);

  const roomName =
    useParams()?.roomName || (searchParams.get("roomName") as string);
  const authToken = searchParams.get("authToken") as string;
  const debug = searchParams.get("debug") === "true";
  let baseUri = searchParams.get("apiBase");

  if (baseUri?.startsWith("https://")) {
    baseUri = baseUri.replace("https://", "");
  }

  if (baseUri?.startsWith("api.")) {
    baseUri = baseUri.replace("api.", "");
  }

  const apiBase = baseUri ? `https://api.${baseUri}` : "https://api.dyte.io";

  useEffect(() => {
    const initializeFlagsmith = async (roomName: string, authToken: string) => {
      const controller = new FlagsmithController(authToken, roomName, apiBase);

      try {
        await controller.init();
      } catch (err) {
        console.error("Failed to initialize flagsmith with error", err);
      }
      setFlagsmith(controller);
    };
    if (authToken) {
      initializeFlagsmith(roomName, authToken);
    }
  }, [roomName, authToken, setFlagsmith, apiBase]);

  useEffect(() => {
    if (flagsmith === null) {
      return;
    }

    const configJson = searchParams.get("config");

    let parsedConfig: MeetingConfig;

    // Set defaults on config object

    if (configJson == null) {
      parsedConfig = DEFAULT_MEETING_CONFIG;
    } else {
      parsedConfig = JSON.parse(atob(configJson));
    }

    if (parsedConfig.uiKit === undefined) {
      parsedConfig.uiKit = DEFAULT_UIKIT;
    }

    if (parsedConfig.waitTimeMs === undefined) {
      parsedConfig.waitTimeMs = DEFAULT_WAIT_TIME_MS;
    }

    parsedConfig.waitTimeMs =
      flagsmith.getWaitTimeMS() || parsedConfig.waitTimeMs;

    if (parsedConfig.watermark === undefined) {
      parsedConfig.watermark = DEFAULT_WATERMARK_CONFIG;
    } else {
      parsedConfig.watermark = {
        ...DEFAULT_WATERMARK_CONFIG,
        ...parsedConfig.watermark,
      };
    }

    setConfig(parsedConfig);
  }, [setConfig, searchParams, flagsmith]);

  if (!authToken) {
    return <p>authToken not provided in query parameters!!</p>;
  }

  if (config == null || flagsmith == null) {
    return <p>Initializing.....</p>;
  }

  // If no roomName is provided, we have to use uiKit as it is a v2 meeting
  const useUiKit = !roomName || config.uiKit || flagsmith.useUiKit();

  return (
    <>
      <UIKitMeeting
        roomName={roomName}
        authToken={authToken}
        config={config}
        baseUri={baseUri}
        debug={debug}
      />

      {config.watermark.enabled && <Watermark config={config.watermark} />}
    </>
  );
}

export default Meeting;
