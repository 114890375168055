import Sockrates from "@dyte-in/sockrates-client";
import DyteClient from "@dytesdk/web-core";

export default class WorkerWs {
    debug: boolean;
   public sockrates: Sockrates | null = null;


    constructor(debug: boolean) {
        this.debug = debug;
    }

    public async connect() {
        if (this.debug){
            console.log("Debug mode enabled, not connecting to websocket");
            return
        }
        const wsUrl = process.env.WS_URL ?? "ws://localhost:8080/ws";
        const sockrates = new Sockrates(wsUrl, {
            queueOnDisconnect: true,
            flushOnReconnect: true,
        });

        console.log("Connecting to websocket");

        sockrates.on("connected", () => {
            console.log("Connected to websocket");
        });

        await sockrates.connect(true);

        this.sockrates = sockrates;
    }

    public async initSetup(meeting: DyteClient | undefined) {
        if (!meeting) {
            console.log("Meeting not initialized");
            return;
        }
        if (!this.sockrates) {
            console.log("socket not initialized");
            return
        }
        meeting.participants.addListener("broadcastedMessage", (event: any) => {
            try {
                if (event.type === "ID3Data") {
                    this.sockrates!.sendRaw(JSON.stringify(event));
                }
            } catch (err) {
                console.log(err);
            }
        });

        // Start recording trigger.
        await this.sockrates!.sendRaw(JSON.stringify({ type: "StartRecording" }));
    }

    public async sendRaw(data: string) {
        if (this.sockrates) {
            await this.sockrates.sendRaw(data);
        }
    }
    
    public async disconnect() {
        if (this.sockrates) {
            await this.sockrates.disconnect();
        }
    }
}