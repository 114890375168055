import React, { useEffect, useRef, useState } from "react";
import { DyteProvider, useDyteClient } from "@dytesdk/react-web-core";
import {
  generateConfig,
  provideDyteDesignSystem,
  UIConfig,
} from "@dytesdk/ui-kit";
import RecordingController from "../controllers/RecordingController";
import { MeetingConfig } from "../types";
import { WebCoreController } from "../controllers/DyteController";
import DyteClient from "@dytesdk/web-core";
import TuringMeeting from "./TuringMeeting";
import WorkerWs from "../utils/ws";

const {
  DyteGrid,
  DyteParticipantsAudio,
  DyteSpinner,
  defaultConfig,
} = require("@dytesdk/react-ui-kit");

const defaultUIConfig = {
  ...defaultConfig,
  designTokens: {
    borderRadius: "rounded",
    borderWidth: "thin",
    spacingBase: 4,
    theme: "dark",
    logo: "",
    colors: {
      brand: {
        "300": "#023dd0",
        "400": "#0248f5",
        "500": "#2160fd",
        "600": "#3e75fd",
        "700": "#5c8afe",
      },
      background: {
        "600": "#222222",
        "700": "#1f1f1f",
        "800": "#1b1b1b",
        "900": "#181818",
        "1000": "#141414",
      },
      danger: "#FF2D2D",
      text: "#EEEEEE",
      "text-on-brand": "#EEEEEE",
      success: "#62A504",
      "video-bg": "#191919",
      warning: "#FFCD07",
    },
  },
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function UIKitMeeting(props: {
  roomName: string;
  authToken: string;
  config: MeetingConfig;
  baseUri: string | null;
  debug?: boolean;
}) {
  const { roomName, authToken, config, baseUri } = props;
  const [controller, setController] = useState<RecordingController | null>(
    null
  );
  const [uiconfig, setuiconfig] = useState<UIConfig | null>(null);
  const [meetingEnded, setMeetingEnded] = useState<boolean>(false);
  const [participantCount, setParticipantCount] = useState<number>(0);
  const [client, initClient] = useDyteClient({});
  const { width, height } = useWindowDimensions();

  const elementRef = useRef(null);
  const workerWsRef = useRef<WorkerWs>(new WorkerWs(props.debug ?? false));

  useEffect(() => {
    (async () => {
      await workerWsRef.current.connect();
      try {
        const meeting = await initClient({
          roomName,
          authToken,
          defaults: {
            audio: false,
            video: false,
          },
          // apiBase: apiBase ?? process.env.REACT_APP_API_BASE, NOTE(rishit): Depreceated in favor of baseURI
          baseURI: baseUri ?? process.env.REACT_APP_API_BASE,
        });
        await workerWsRef.current.initSetup(meeting as unknown as DyteClient);
      } catch (err) {
        const typedErr = err as {
          code: number | undefined;
          name: string | undefined;
          message: string | undefined;
        };

        const { message } = typedErr;

        console.log("Sending dyte error message");

        await workerWsRef.current.sendRaw(
          JSON.stringify({
            type: "ClientInitFailed",
            payload: {
              error: message ?? (err as string),
            },
          })
        );
      }
    })();

    return () => {
      console.log("Disconnecting Websocket");
      const ws = workerWsRef;
      ws.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (client !== undefined) {
      let uiKitConfig = defaultUIConfig as UIConfig;

      try {
        const presetConfig = client.self.config;
        uiKitConfig = generateConfig(presetConfig as any, client as any).config;
      } catch (error) {
        uiKitConfig = defaultUIConfig as UIConfig;
      }
      setuiconfig(uiKitConfig);

      const controller = new RecordingController(
        new WebCoreController(client as any),
        config
      );
      setController(controller);
      controller.setRecorderWorkerWs(workerWsRef.current.sockrates);

      controller.on("meetingEnded", () => {
        setMeetingEnded(true);
      });

      controller.on("participantCount", (count: number) => {
        setParticipantCount(count);
      });
      try {
        client.joinRoom();
      } catch (err) {
        console.log(err);
      }
    }
  }, [client, config]);

  useEffect(() => {
    if (uiconfig && width && height && uiconfig.root) {
      const gridWidth = width > height ? "15%" : "100%";
      uiconfig.root["dyte-mixed-grid"] = {
        states: ["activeSpotlight"],
        children: [["dyte-simple-grid", { style: { width: gridWidth } }]],
      };

      uiconfig.root["dyte-mixed-grid.activeSpotlight"] = [
        [
          "dyte-spotlight-grid",
          { style: { width: gridWidth }, layout: "column" },
        ],
      ];

      setuiconfig(uiconfig);
    }
  }, [uiconfig, width, height]);

  useEffect(() => {
    if (elementRef.current && uiconfig && uiconfig.designTokens) {
      provideDyteDesignSystem(elementRef.current, uiconfig.designTokens);
      provideDyteDesignSystem(elementRef.current, {
        borderRadius: "sharp",
      });
    }
  }, [elementRef, uiconfig]);

  useEffect(() => {
    return () => {
      if (controller) {
        controller.cleanup();
      }
    };
  }, [controller]);

  if (!client || !uiconfig) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <DyteSpinner />
      </div>
    );
  }

  if (meetingEnded) {
    // If meeting has ended, just return a blank component, golang side
    // should end the recording shortly.
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(var(--dyte-colors-background-1000, 8 8 8))",
        }}
        ref={elementRef}
      ></div>
    );
  }

  if (
    client.self.organizationId === "35944d93-64e6-42a4-a262-f496a15a978b" ||
    client.__internals__.features.hasFeature("turing_layout")
  )
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(var(--dyte-colors-background-1000, 8 8 8))",
        }}
        ref={elementRef}
      >
        <DyteProvider value={client} fallback={<DyteSpinner />}>
          <TuringMeeting />
        </DyteProvider>
      </div>
    );

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(var(--dyte-colors-background-1000, 8 8 8))",
      }}
      ref={elementRef}
    >
      <DyteGrid
        config={uiconfig}
        meeting={client}
        gap={0}
        aspectRatio={
          participantCount <= 4
            ? `${Math.floor(width)}:${Math.floor(height)}`
            : "16:9"
        }
      />
      <DyteParticipantsAudio meeting={client} />
    </div>
  );
}
